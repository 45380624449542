@import 'mixins';

*{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


html{
    scroll-behavior: smooth;
}

*{
    margin: 0px;
    padding: 0px;
    font-family: $robot;
}

.submenu-close{
    display: none;
}

.extra-menu-close{
    display: none;
}

.site-header{
    width: 100%;
    height: 150px;
    // background-color: red;
    background-color: white;
    @include flexColumn(flex-start);
    align-items: center;

    &__top-container{
        width: 100%;
        height: 49px;
        // background-color: black;
        @include flexRow(center);
        border-bottom: 1px solid lightgray;
        background-color: darken(white, 7%);
        
        .site-header__container{
            max-width: 1360px;
            width: 100%;
            height: 100%;
            // background-color: yellow;
            @include flexRow(space-between);
            align-items: center;
        }
    }
        
    &__city-name{
        @include flexRow(flex-start);
        align-items: center;
        // background-color: pink;
        font-size: 12px;
        color: darken(white, 45%);
        transition: 0.3s;
        cursor: pointer;

        svg{
            width: 5px;
            height: 5px;
            margin-left: 5px;
            margin-top: 1px;
            path{
                transition: 0.3s;
                fill: darken(white, 45%);
            }
        }

        &:hover{
            color: darken(white, 95%);
            svg{
                path{
                    fill: darken(white, 95%);
                }
            }
        }
    }

    &__call-button{
        font-size: 12px;
        color: $accent;
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            // color: white;
            text-decoration: underline;
        }
    }

    &__address{
        font-size: 12px;
        color: darken(white, 45%);
        text-decoration: none;
    }

    &__phone{
        font-size: 14px;
        // color: darken(white, 45%);
        text-decoration: none;
        color: $accent;
        transition: 0.3s;
        &:hover{
            text-decoration: underline;
        }
    }

    &__buttons-block{
        // background-color: pink;
        @include flexRow(center);
    }

    &__search-button{
        // margin-right: 50px;
        @include flexRow(flex-start);
        align-items: center;
        background-color: transparent;
        // background-color: blue;
        font-size: 14px;
        color: darken(white, 45%);
        border: none;
        outline: none;
        cursor: pointer;
        transition: 0.3s;
        svg{
            width: 20px;
            height: 20px;
            margin-right: 10px;
            // fill: darken(white, 45%);
            rect{
                transition: 0.3s;
                fill: darken(white, 45%);
                // fill: red;
            }
            circle{
                transition: 0.3s;
                stroke: darken(white, 45%);
            }
        }

        &:hover{
            color: darken(white, 95%);
            svg{
                rect{
                    fill: darken(white, 95%);
                }
                circle{
                    stroke: darken(white, 95%);
                }
            }
        }
    }

    &__cab-button{
        @include flexRow(flex-start);
        align-items: center;
        // background-color: blue;
        font-size: 14px;
        color: darken(white, 45%);
        border: none;
        outline: none;
        cursor: pointer;
        transition: 0.3s;
        svg{
            width: 20px;
            height: 20px;
            margin-right: 10px;
            circle{
                transition: 0.3s;
                stroke: darken(white, 45%);
            }
        }

        &:hover{
            color: darken(white, 95%);
            svg{
                circle{
                    stroke: darken(white, 95%);
                }
            }
        }
    }

    &__bottom-container{
        // display: none;
        max-width: 1360px;
        width: 100%;
        height: 100px;
        // background-color: yellow;
        @include flexRow(space-between);
    }

    &__logo-container{
        // background-color: pink;
        @include flexRow(flex-start);
        width: 400px;
    }

    &__logo-block{
        width: 200px;
        height: 100%;
        // background-color: plum;
        @include flexRow(center);
        align-items: center;
    }

    &__logo{
        max-width: 160px;
        max-height: 100%;
    }

    &__motto{
        // display: none;
        width: 190px;
        // background-color: plum;
        margin-left: 10px;
        @include flexColumn(center);
        text-align: center;
        color: darken(white, 35%);
        letter-spacing: 0.7px;
        font-size: 13px;
    }

    &__mobile-button{
        display: none;
    }

    &__nav{
        // background-color: pink;
        @include flexRow(center);
    }

    &__menu{
        // background-color: plum;
        @include flexRow(center);
        list-style: none;
    }

    &__menu-item{
        // background-color: orange;
        margin: 0px 15px 0px 15px;
        @include flexColumn(center);
        // position: relative;
        &:last-child{
            margin-right: 0px;
        }
    }

    &__menu-link{
        cursor: pointer;
        // background-color: aqua;
        width: 100%;
        height: 100%;
        // @include flexColumn(center);
        @include flexRow(flex-start);
        align-items: center;
        // align-content: center;
        text-align: center;
        text-decoration: none;
        letter-spacing: 1px;
        color: black;
        font-size: 12px;
        transition: 0.3s;
        font-weight: 700;
        text-transform: uppercase;
        &:hover{
            color: $accent;
        }

        &:first-child{
            // align-items: flex-start;
            // @include flexRow(flex-start);
            svg{
                margin-right: 10px;
                path{
                    transition: 0.3s;
                    fill: black;
                }
            }
            &:hover{
                svg{
                    margin-right: 10px;
                    path{
                        fill: $accent;
                    }
                }

            }
        }
    }

    &__cats-menu{
        position: absolute;
        max-width: 540px;
        width: 100%;
        padding: 10px;
        background-color: darken(white, 5%);
        // background-color: red;
        // height: 300px;
        z-index: 100;
        top: 100px;
        border-radius: 0px 0px 5px 5px;
        @include flexRow(flex-start);
        flex-wrap: wrap;
        transition: 0.3s;
        display: none;
    }

    .show-cats-menu{
        &:hover{
            .site-header__cats-menu{
                display: flex;
            }
        }
    }

    &__cat-item{
        width: 250px;
        min-height: 30px;
        // background-color: yellow;
        margin: 10px;
        @include flexRow(flex-start);
        align-items: center;
        position: relative;
    }
    
    &__cat-link{
        width: 100%;
        height: 100%;
        align-items: center;
        @include flexRow(flex-start);
        // background-color: pink;
        text-decoration: none;
        color: black;
        font-size: 14px;
        transition: 0.3s;
        &:hover{
            color: $accent;
        }
    }

    &__cat-img-block{
        width: 30px;
        height: 30px;
        // background-color: plum;
        @include flexRow(center);
        align-items: center;
    }

    &__cat-img{
        max-width: 100%;
        max-height: 100%;
    }

    &__cat-name{
        width: 210px;
        height: 100%;
        // background-color: plum;
        margin-left: 10px;
        @include flexRow(flex-start);
        align-items: center;
    }


    &__cats-submenu{
        position: absolute;
        // max-width: 810px;
        width: 810px;
        // width: calc(100% - 250px);
        padding: 10px;
        background-color: darken(white, 10%);
        // background-color: yellow;
        // height: 300px;
        z-index: 100;
        top: -20px;
        // right: 0px;
        left: 250px;
        border-radius: 0px 5px 5px 5px;
        @include flexRow(flex-start);
        flex-wrap: wrap;
        transition: 0.3s;
        display: none;
    }

    .show-cats-submenu{
        &:hover{
            .site-header__cats-submenu{
                display: flex;
            }
        }
    }

    &__menu-header{
        // background-color: pink;
        // width: calc(100% - 50px);
        width: 100%;
        margin-top: 10px;
        color: $accent;
    }

    &__cats-submenu-header{
        // background-color: pink;
        // width: calc(100% - 50px);
        width: 100%;
        font-size: 16px;
        color: $accent;
        &:not(:first-child){
            margin-top: 10px;
        }
    }

    &__cat-subitem{
        width: 250px;
        min-height: 30px;
        // background-color: pink;
        margin: 5px 10px 5px 10px;
        font-size: 14px;
        @include flexRow(flex-start);
        align-items: center;
    }
    
    &__cat-sublink{
        width: 100%;
        height: 100%;
        align-items: center;
        @include flexRow(flex-start);
        // background-color: pink;
        text-decoration: none;
        font-size: 14px;
        // text-align: center;
        color: black;
        transition: 0.3s;
        &:hover{
            color: $accent;
        }
    }

    &__cat-subname{
        width: 210px;
        height: 100%;
        // background-color: plum;
        margin-left: 10px;
        @include flexRow(flex-start);
        align-items: center;
    }

    &__brokes-menu{
        position: absolute;
        max-width: 540px;
        width: 100%;
        padding: 10px;
        background-color: darken(white, 5%);
        // background-color: red;
        // height: 300px;
        right: 500px;
        z-index: 100;
        top: 100px;
        border-radius: 0px 0px 5px 5px;
        @include flexRow(flex-start);
        flex-wrap: wrap;
        transition: 0.3s;
        display: none;
    }

    .show-brokes-menu{
        &:hover{
            .site-header__brokes-menu{
                display: flex;
            }
        }
    }

    &__broke-item{
        width: 250px;
        height: 40px;
        // background-color: yellow;
        margin: 10px;
        @include flexRow(flex-start);
        align-items: center;
        position: relative;
    }
    
    &__broke-link{
        width: 100%;
        height: 100%;
        align-items: center;
        @include flexRow(flex-start);
        // background-color: pink;
        text-decoration: none;
        color: black;
        font-size: 14px;
        transition: 0.3s;
        &:hover{
            color: $accent;
        }
    }

    &__broke-img-block{
        width: 30px;
        height: 30px;
        // background-color: plum;
        @include flexRow(center);
        align-items: center;
    }

    &__broke-img{
        max-width: 100%;
        max-height: 100%;
    }

    &__broke-name{
        width: 210px;
        height: 100%;
        // background-color: plum;
        margin-left: 10px;
        @include flexRow(flex-start);
        align-items: center;
    }


    &__brokes-submenu{
        position: absolute;
        // max-width: 810px;
        width: 480px;
        // width: calc(100% - 250px);
        padding: 10px;
        // left: -200px;
        background-color: darken(white, 10%);
        // background-color: yellow;
        // height: 300px;
        z-index: 100;
        top: -20px;
        // right: 0px;
        left: 250px;
        border-radius: 0px 5px 5px 5px;
        @include flexRow(flex-start);
        flex-wrap: wrap;
        transition: 0.3s;
        display: none;
    }

    .show-brokes-submenu{
        &:hover{
            .site-header__brokes-submenu{
                display: flex;
            }
        }
    }

    &__brokes-submenu-header{
        // background-color: pink;
        // width: calc(100% - 50px);
        width: 100%;
        font-size: 16px;
        color: $accent;
        &:not(:first-child){
            margin-top: 10px;
        }
    }

    &__broke-subitem{
        // width: 100%;
        min-height: 40px;
        // background-color: pink;
        margin: 10px;
        @include flexRow(flex-start);
        align-items: center;
    }
    
    &__broke-sublink{
        width: 100%;
        height: 100%;
        align-items: center;
        @include flexRow(flex-start);
        // background-color: pink;
        text-decoration: none;
        font-size: 14px;
        // text-align: center;
        color: black;
        transition: 0.3s;
        &:hover{
            color: $accent;
        }
    }

    &__broke-subname{
        width: 210px;
        height: 100%;
        // background-color: plum;
        margin-left: 10px;
        @include flexRow(flex-start);
        align-items: center;
    }

    &__cities-block{
        width: 100%;
        height: 100%;
        background-color:  transparentize(#3A3F44, 0.7);
        // background-color: white;
        backdrop-filter: blur(2px);
        position: absolute;
        @include flexRow(center);
        align-items: center;
        display: none;
        z-index: 9999;
    }

    &__cities-container{
        max-width: 780px;
        width: calc(100% - 120px);
        max-height: 490px;
        height: calc(100% - 110px);
        // background-color: $black;
        background-color: white;
        @include flexColumn(flex-start);
        border-radius: 5px;
        position: relative;
        padding: 55px 60px 55px 60px;
    }

    &__close-cities{
        cursor: pointer;
        width: 46px;
        height: 46px;
        // background-color: pink;
        position: absolute;
        right: 0px;
        top: 0px;
        @include flexRow(center);
        align-items: center;
        svg{
            transition: 0.3s;
            path{
                fill: $light-gray;
            }
        }
        &:hover{
            svg{
                transform: rotate(90deg);
            }
        }
    }

    &__cities-header{
        width: 100%;
        height: 50px;
        // background-color: pink;
        font-size: 24px;
        font-weight: 400;
        // color: white;
    }

    &__search-city-block{
        width: calc(100% - 2px);
        height: 48px;
        margin-top: 15px;
        // background-color: pink;
        border: 1px solid $light-gray;
        // background-color: black;
        background-color: darken(white, 10%);
        border-radius: 3px;
        @include flexRow(flex-start);
    }

    &__search-city{
        border: none;
        width: calc(93% - 20px);
        padding: 0px 10px 0px 10px;
        background-color: transparent;
        height: 100%;
        // background-color: plum;
        outline: none;
    }

    &__search-city-button{
        width: 7%;
        height: 100%;
        // background-color: orange;
        @include flexRow(center);
        align-items: center;
    }

    &__cities-list{
        width: calc(100% - 29px);
        height: 279px;
        padding: 16px 14px 16px 14px;
        // background-color: pink;
        // background-color: #2b2b2b;
        margin-top: 65px;
        list-style: none;
        @include flexRow(center);
        flex-wrap: wrap;
        overflow-y: scroll;
        border: 1px solid $light-gray;
        background-color: darken(white, 10%);
        border-radius: 3px;

        &::-webkit-scrollbar{
            width: 4px;
        }

        &::-webkit-scrollbar-thumb{
            background-color: $light-gray;
            border: 50px;
        }
    }

    &__city-item{
        // background-color: plum;
        margin: 15px;
    }

    &__city-link{
        text-decoration: none;
        color: black;
        &:hover{
            color: $accent;
        }
    }

    &__brands-block{
        width: 100%;
        height: 100%;
        background-color:  transparentize(#3A3F44, 0.7);
        backdrop-filter: blur(2px);
        position: absolute;
        @include flexRow(center);
        align-items: center;
        display: none;
        z-index: 9999;
    }

    &__brands-container{
        max-width: 780px;
        width: calc(100% - 120px);
        max-height: 490px;
        height: calc(100% - 110px);
        background-color: white;
        @include flexColumn(flex-start);
        border-radius: 5px;
        position: relative;
        padding: 55px 60px 55px 60px;
    }

    &__close-brands{
        cursor: pointer;
        width: 46px;
        height: 46px;
        // background-color: pink;
        position: absolute;
        right: 0px;
        top: 0px;
        @include flexRow(center);
        align-items: center;
        svg{
            transition: 0.3s;
            path{
                fill: $light-gray;
            }
        }
        &:hover{
            svg{
                transform: rotate(90deg);
            }
        }
    }

    &__brands-header{
        width: 100%;
        height: 50px;
        // background-color: pink;
        font-size: 24px;
        font-weight: 400;
        color: black;
    }

    &__search-brand-block{
        width: calc(100% - 2px);
        height: 48px;
        margin-top: 15px;
        // background-color: pink;
        border: 1px solid darken(white, 5%);
        background-color: darken(white, 3%);
        border-radius: 3px;
        @include flexRow(flex-start);
    }

    &__search-brand{
        border: none;
        width: calc(93% - 20px);
        padding: 0px 10px 0px 10px;
        background-color: transparent;
        height: 100%;
        // background-color: plum;
        outline: none;
    }

    &__search-brand-button{
        width: 7%;
        height: 100%;
        // background-color: orange;
        @include flexRow(center);
        align-items: center;
        svg{
            circle{
                stroke: darken(white, 45%);
            }
            rect{
                fill: darken(white, 45%);
            }
        }
    }

    &__brands-list{
        width: calc(100% - 29px);
        height: 279px;
        padding: 16px 14px 16px 14px;
        background-color: darken(white, 3%);
        margin-top: 65px;
        list-style: none;
        @include flexRow(center);
        flex-wrap: wrap;
        overflow-y: scroll;
        border: 1px solid darken(white, 5%);
        // background-color: pink;
        border-radius: 3px;
        @include flexRow(center);
        flex-wrap: wrap;

        &::-webkit-scrollbar{
            width: 4px;
        }

        &::-webkit-scrollbar-thumb{
            background-color: $light-gray;
            border: 50px;
        }
    }

    &__brand-item{
        // background-color: plum;
        min-width: 100px;
        padding: 10px;
        margin: 15px;
        border-radius: 5px;
        background-color: $accent;
        color: white;
        text-decoration: none;
        text-align: center;
        &:hover{
            text-decoration: underline;
        }
    }
    

    &__auth-block{
        width: 100%;
        height: 100%;
        background-color:  transparentize(#3A3F44, 0.7);
        backdrop-filter: blur(2px);
        position: absolute;
        @include flexRow(center);
        align-items: center;
        display: none;
        z-index: 99999;
    }

    &__auth-container{
        max-width: 450px;
        width: calc(100% - 120px);
        max-height: 350px;
        height: calc(100% - 110px);
        background-color: white;
        @include flexColumn(flex-start);
        border-radius: 5px;
        position: relative;
        padding: 55px 60px 55px 60px;
    }

    &__close-auth{
        cursor: pointer;
        width: 46px;
        height: 46px;
        // background-color: pink;
        position: absolute;
        right: 0px;
        top: 0px;
        @include flexRow(center);
        align-items: center;
        svg{
            transition: 0.3s;
            path{
                fill: $light-gray;
            }
        }
        &:hover{
            svg{
                transform: rotate(90deg);
            }
        }
    }

    &__auth-header{
        width: 100%;
        height: 50px;
        // background-color: pink;
        font-size: 24px;
        font-weight: 400;
        // color: white;
    }

    &__auth-label{
        width: 100%;
        height: 75px;
        // background-color: pink;
        @include flexColumn(flex-start);
        margin-bottom: 20px;
    }

    &__auth-input-name{
        font-size: 14px;
        // color: $light-gray;
        margin-bottom: 5px;
        // background-color: plum;
        height: 20px;
    }

    &__auth-input{
        border: none;
        width: calc(100% - 22px);
        padding: 0px 10px 0px 10px;
        background-color: transparent;
        // background-color: plum;
        outline: none;
        height: 50px;
        border-radius: 3px;
        border: 1px solid $light-gray;
        transition: 0.3s;
        color: white;
        &::placeholder{
            color: $light-gray;
        }

        &:focus{
            // background-color: darken($light-gray, 55%);
            border-color: black;
        }
    }

    &__auth-error{
        width: 100%;
        height: 50px;
        // background-color: plum;
        margin-bottom: 10px;
        text-align: center;
        @include flexColumn(center);
        color: white;
        // display: none;
    }

    &__auth-button{
        width: 100%;
        height: 50px;
        background-color: $accent;
        border-radius: 3px;
        text-align: center;
        @include flexColumn(center);
        align-items: center;
        text-decoration: none;
        color: white;
        cursor: pointer;
        background-position: center;
        transition: background 0.8s;
        border: none;

        &:hover {
            background: $accent radial-gradient(circle, transparent 1%, $accent  1%) center/15000%;
        }

        &:active {
            background-color: lighten($accent, 10%);
            background-size: 100%;
            transition: background 0s;
        }
    }
}

@media (max-width: 1360px){
    .submenu-close{
        // display: flex;
        @include flexRow(center);
    }
    
    .extra-menu-close{
        // display: flex;
        @include flexRow(center);
    }

    .site-header{
        // display: none;
        // background-color: red;
        height: unset;

        &__top-container{
            height: unset;
            // background-color: yellow;
            order: 2;
            // flex-wrap: wrap;

            .site-header__container{
                // background-color: pink;
                // flex-wrap: wrap;
                @include flexColumn(flex-start);
            }
        }

        &__city-name{
            margin: 10px;
        }

        &__search-city-button{
            display: none;
        }

        &__call-button{
            margin: 10px;
        }
        
        &__address{
            margin: 10px;
        }

        &__phone{
            margin: 10px;
        }

        &__search-button{
            display: none;
        }

        &__brands-container{
            padding: 10px;
            width: calc(100% - 20px);
        }

        &__search-brand-button{
            display: none;
        }

        &__bottom-container{
            order: 1;
            height: unset;
            @include flexColumn(flex-start);
            align-items: center;
        }

        &__logo-container{
            width: 100%;
            // background-color: yellow;
            @include flexColumn(flex-start);
            align-items: center;
            margin-top: 15px;
        }

        &__motto{
            margin-left: 0px;
            margin-top: 10px;
        }

        &__mobile-button{
            width: 40px;
            // background-color: red;
            height: 40px;
            margin-top: 10px;
            @include flexColumn(center);
            align-items: center;
            svg{
                path{
                    stroke: black;
                }
            }
        }

        &__mobile-button_active{
            // background-color: red;
            @include flexRow(center);
            align-items: center;
            path{
                &:nth-child(2){
                    opacity: 0;
                }
                
                &:nth-child(1){
                    transform: rotate(-45deg) translate(8px, 11px);
                    // transform: translate(10px);
                    transform-origin: 20px 20px;
                }
                &:nth-child(3){
                    // opacity: 0;
                    transform-origin: 20px 20px;
                    transform: rotate(45deg) translate(-5px, -6px);
                    // transform: translate(10px);
                }
            }
        }

        &__nav{
            // background-color: red;
            margin-top: 10px;
            width: 100%;
            display: none;
        }

        &__menu{
            @include flexColumn(flex-start);
            // background-color: yellow;
            align-items: center;
            width: 100%;
        }

        &__menu-item{
            text-align: center;
            // background-color: pink;
            width: 100%;
            text-align: center;
            margin-right: 0px;
            margin-bottom: 10px;
            margin-left: 0px;
            &:first-child{
                svg{
                    display: none;
                }
            }
        }
        
        &__menu-link{
            // background-color: plum;
            @include flexRow(center);
            width: 100%;
            margin-left: 0px;
            text-align: center;
        }

        &__cats-menu,
        &__brokes-menu{
            // background-color: red;
            padding: 0px;
            max-width: 100%;
            // @include flexRow(flex-start);
            left: 0px;
        }

        &__cat-item,
        &__broke-item{
            width: 100%;
            // background-color: pink;
            margin: 0px;
            padding: 10px;
        }

        &__cat-name,
        &__broke-name{
            width: calc(100% - 45px);
            // background-color: plum;
            text-align: center;
            margin-left: 0px;
            @include flexRow(center);
        }

        &__cats-submenu,
        &__brokes-submenu{
            // @include flexRow(flex-start);
            left: 0px;
            width: 100%;
            top: -10px;
            padding: 10px 0px 10px 0px;
            // background-color: red;
            border-radius: 0px;
            // display: none;
        }

        &__cat-subitem,
        &__broke-subitem{
            width: 100%;
            // background-color: yellow;
            text-align: center;
        }
        
        &__cat-sublink,
        &__broke-sublink{
            @include flexRow(center);
        }
        
        &__cat-subname,
        &__broke-subname{
            @include flexRow(center);
            text-align: center;
            margin-left: 0px;
        }

        .extra-menu-close{
            color: red;
        }
    }

}
